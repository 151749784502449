import React from 'react'
import './audit-logs.css'
import 'devextreme/dist/css/dx.common.css'
import 'devextreme/dist/css/dx.light.compact.css'
import 'devextreme/data/odata/store'
import DataGrid, {
  Column,
  RemoteOperations,
  Pager,
} from 'devextreme-react/data-grid'
import CustomStore from 'devextreme/data/custom_store'
import 'whatwg-fetch'
import { getAuditLog } from '../../services/audit'
import moment from 'moment'

const cellRender = (data) => `${data.value === null ? '$0.00' : data.value}`

class AuditLogsComponent extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      loaded: false,
      email: '',
      firstName: '',
      lastName: '',
      phone: '',
      usersStatus: '',
      submitted: false,
    }

    this.getAuditLogs = this.getAuditLogs.bind(this)
  }

  getAuditLogs() {
    return {
      store: new CustomStore({
        load: (loadOptions) => {
          return getAuditLog(loadOptions)
        },
      }),
    }
  }

  render() {
    return (
      <div className="px-3">
        <h4>Audit Logs</h4>
        <DataGrid
          dataSource={this.getAuditLogs()}
          showBorders={true}
          columnAutoWidth={true}
        >
          <Pager
            allowedPageSizes={[10, 20, 50]}
            showPageSizeSelector={true}
            showNavigationButtons={true}
            showInfo={true}
            visible={true}
            infoText="Page {0} of {1} ({2} items)"
          />
          <RemoteOperations sorting={false} paging={true} filtering={false} />
          <Column
            allowSorting={false}
            dataField={'date'}
            dataType={'date'}
            calculateCellValue={(d) => moment.utc(d.date).format('YYYY/MM/DD')}
          />
          <Column allowSorting={false} dataField={'adminEmail'} />
          <Column allowSorting={false} dataField={'userEmail'} />
          <Column allowSorting={false} dataField={'action'} />
          <Column allowSorting={false} dataField={'userId'} />
          <Column
            allowSorting={false}
            dataField={'amount'}
            cellRender={cellRender}
          />
          <Column allowSorting={false} dataField={'country'} />
        </DataGrid>
      </div>
    )
  }
}

export default AuditLogsComponent
