import {
  fetchWithHeaders,
  handleServerError,
  NOTIFICATIONS,
  parseResponse,
} from '../helpers/utils'
import * as UserService from '../services/user'
import * as ACTION_CONSTANTS from './action_constants'
import { toast } from 'react-toastify'

export function updatingUserInfo(userInfo) {
  return {
    type: ACTION_CONSTANTS.UPDATING_USER_INFO,
    userInfo,
  }
}

export function updatedUserInfo(user) {
  return {
    type: ACTION_CONSTANTS.UPDATED_USER_INFO,
    user,
  }
}

export function errorUpdatingUserInfo(userInfo, error) {
  return {
    type: ACTION_CONSTANTS.ERROR_UPDATING_USER_INFO,
    userInfo,
    error,
  }
}

function requestUser() {
  return {
    type: ACTION_CONSTANTS.REQUEST_USER,
    loading: true,
  }
}

function receiveUser(user) {
  return {
    type: ACTION_CONSTANTS.RECEIVE_USER,
    user,
  }
}

export function loadUserDetails(id) {
  return (dispatch) => {
    dispatch(requestUser())
    return fetchWithHeaders(`users/${id}`, {
      method: 'GET',
    })
      .then(parseResponse)
      .then((data) => dispatch(receiveUser(data)))
      .catch(handleServerError)
  }
}

function requestReactivateUser() {
  return {
    type: 'REQUEST_REACTIVATE_USER',
    loading: true,
  }
}

function responseReactivateUser(user) {
  return {
    type: 'REACTIVATE_USER',
    user,
  }
}

export function reactivateUser(customerId) {
  return (dispatch) => {
    dispatch(requestReactivateUser())
    return fetchWithHeaders(`users/${customerId}/activate`, {
      method: 'POST',
    })
      .then(() =>
        fetchWithHeaders(`users/${customerId}`, {
          method: 'GET',
        })
      )
      .then(parseResponse)
      .then((data) => dispatch(responseReactivateUser(data)))
      .catch(handleServerError)
  }
}

function requestDeactivateUser() {
  return {
    type: 'REQUEST_DEACTIVATE_USER',
    loading: true,
  }
}

function responseDeactivateUser(user) {
  return {
    type: 'DEACTIVATE_USER',
    user,
  }
}

function requestImpersonateUser() {
  return {
    type: 'REQUEST_IMPERSONATE_USER',
    loading: true,
  }
}

function responseImpersonateUser(user) {
  return {
    type: 'IMPERSONATE_USER',
    user,
  }
}

export function deactivateUser(customerId) {
  return (dispatch) => {
    dispatch(requestDeactivateUser())
    return fetchWithHeaders(`users/${customerId}/deactivate`, {
      method: 'POST',
    })
      .then(() =>
        fetchWithHeaders(`users/${customerId}`, {
          method: 'GET',
        })
      )
      .then(parseResponse)
      .then((data) => dispatch(responseDeactivateUser(data)))
      .catch(handleServerError)
  }
}

export function impersonateUser(customerId, enable) {
  return (dispatch) => {
    dispatch(requestImpersonateUser())
    return fetchWithHeaders(`users/${customerId}/impersonate`, {
      method: 'POST',
      body: JSON.stringify({ enable }),
    })
      .then(() =>
        fetchWithHeaders(`users/${customerId}`, {
          method: 'GET',
        })
      )
      .then(parseResponse)
      .then((data) => dispatch(responseImpersonateUser(data)))
      .catch(handleServerError)
  }
}

export function deleteUser({ userId, email }) {
  return async (dispatch) => {
    return fetchWithHeaders(`users/gdpr`, {
      method: 'POST',
      body: JSON.stringify({ userId, email }),
    }).then(parseResponse)
  }
}

function requestChangeOnRenewalStatus() {
  return {
    type: ACTION_CONSTANTS.REQUEST_CHANGE_ON_RENEWAL_STATUS,
    loading: true,
  }
}

function receiveChangeOnRenewalStatus(user) {
  return {
    type: ACTION_CONSTANTS.RECEIVE_CHANGE_ON_RENEWAL_STATUS,
    user,
  }
}

/**
 * Dispatcher for deactivate a user subscription at end of billing cycle
 * @param customerId
 * @returns {function(...[*]=)}
 */
export function deactivateOnRenewal(customerId) {
  return async (dispatch) => {
    dispatch(requestChangeOnRenewalStatus())
    try {
      await UserService.unsubscribeUser(customerId)
      const user = await UserService.getUserById(customerId)
      return dispatch(receiveChangeOnRenewalStatus(user))
    } catch (e) {
      return handleServerError(e)
    }
  }
}

/**
 * Dispatcher for reactivating a subscription that will be cancelled at end of
 * billing cycle
 * @param customerId
 * @returns {function(...[*]=)}
 */
export function reactivateSubscription(customerId) {
  return async (dispatch) => {
    dispatch(requestChangeOnRenewalStatus())
    try {
      await UserService.reactivateSubscription(customerId)
      const user = await UserService.getUserById(customerId)
      return dispatch(receiveChangeOnRenewalStatus(user))
    } catch (e) {
      return handleServerError(e)
    }
  }
}

export function deactivateImmediately(customerId) {
  return (dispatch) => {
    dispatch(requestDeactivateImmediately())
    return fetchWithHeaders(`subscriptions/cancel?user=${customerId}`, {
      method: 'POST',
    })
      .then(() =>
        fetchWithHeaders(`users/${customerId}`, {
          method: 'GET',
        })
      )
      .then(parseResponse)
      .then((data) => dispatch(responseDeactivateImmediately(data)))
      .catch(handleServerError)
  }
}

export function responseDeactivateImmediately(user) {
  return {
    type: 'DEACTIVATE_IMMEDIATELY',
    user,
  }
}

export function requestDeactivateImmediately() {
  return {
    type: 'REQUEST_DEACTIVATE_IMMEDIATELY',
    loading: true,
  }
}

export function updateUserInfo(userInfo) {
  return (dispatch) => {
    dispatch(updatingUserInfo(userInfo))
    return UserService.updateUser(userInfo)
      .then((user) => {
        // Re-insert update billing info on success because not returned in response
        toast.success('User Information Updated.', {
          containerId: NOTIFICATIONS.USER_NOTIFICATIONS,
        })
        dispatch(
          updatedUserInfo({
            ...userInfo,
            ...user,
          })
        )
      })
      .catch((error) => {
        toast.error(error.message, {
          containerId: NOTIFICATIONS.USER_NOTIFICATIONS,
        })
        dispatch(errorUpdatingUserInfo(userInfo, error))
      })
  }
}
