import { fetchWithHeaders, parseResponse } from '../helpers/utils'
import swal from 'sweetalert'

export const getAuditLog = (loadOptions) => {
  const { skip = 0, take = 5 } = loadOptions || {}

  return fetchWithHeaders(`audit?skip=${skip}&take=${take}`, {
    method: 'GET',
  })
    .then(parseResponse)
    .then((data) => {
      if (data.error) {
        swal(data.error.userTitle, data.error.userDescription, 'error')
      } else {
        return {
          data: data.log,
          totalCount: data.metadata.totalCount,
        }
      }
    })
    .catch(() => {
      throw new Error('Error loading logs.')
    })
}
